.btn {
  background-color: #d91604;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 18px;

  color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;

  cursor: pointer;
}
