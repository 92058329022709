.map-page {
  background-color: #f5ebdc;
  padding-top: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.map-page-content {
  align-items: center;
  width: 80%;
  max-width: 1200px;
  min-height: 83.9vh;
  margin-bottom: 10px;
}
