.dropdown-menu {
  font-family: "Lilita One", sans-serif;
  color: #512314;
  padding-top: 20px;
  font-size: clamp(1rem, 2vw, 1.5rem);
  width: 100%;
}

.dropdown-menu-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  border-bottom: 5px solid #512314;
  cursor: pointer;
  padding-bottom: 5px;
}

.dropdown-menu-items {
  padding-top: 0.625rem;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.625rem;
}

@media (max-width: 1200px) {
  .checkbox-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.625rem;
  }
}
