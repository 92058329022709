.about-page {
  background-color: #f5ebdc;
  padding-top: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-height: 83.9vh;
  margin-bottom: 10px;
}

.about-page-title h1 {
  color: #512314;
  font-family: "Roboto Slab", serif;
  font-size: 36px;
  border-bottom: 3px solid;
  padding-left: 10px;
  padding-right: 10px;
}

.about-page-text {
  width: 100%;
  align-items: left;
  color: #512314;
  font-family: "Teachers", sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-align: justify;
}

.about-page-text a {
  color: #512314;
  padding-left: 10px;
  padding-right: 10px;
}

.about-page-text ul {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media (max-width: 1200px) {
  .about-page-text {
    width: 80%;
    font-size: 24px;
    word-break: normal;
  }
}

@media (max-width: 700px) {
  .about-page-text {
    width: 80%;
    font-size: 18px;
    word-break: normal;
  }
}

@media (max-width: 450px) {
  .about-page-text {
    width: 80%;
    font-size: 16px;
    word-break: normal;
  }

  .about-page-text ul {
    margin-left: -10px;
  }

  .about-page-text li {
    text-align: left;
  }
}
