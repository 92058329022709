.banner {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 1.41px rgba(0, 0, 0, 0.2);
}

.banner-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 3rem;
  color: inherit;
  color: #512314;
}

.banner-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.updated {
  justify-content: start;
}

.items {
  justify-content: end;
}

.banner-icon {
  margin-right: 0.5rem;
}

.banner-text .update-label {
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  padding-left: 2px;
}

.banner-text .update-text {
  font-family: "Teachers", sans-serif;
  font-optical-sizing: auto;
  padding-left: 0.5rem;
  font-weight: 550;
}

@media (max-width: 1200px) {
  .banner-content {
    width: 80%;
  }
}

@media (max-width: 450px) {
  .banner-content {
    width: 90%;
  }
}
