.home-page {
  background-color: #f5ebdc;
  padding-top: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-height: 83.9vh;
  margin-bottom: 10px;
}

.home-page-news-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
}

@media (max-width: 1200px) {
  .home-page-news-content {
    display: flex;
    justify-content: center;
  }
}
