.news-element {
  width: 575px;
  height: 500px;
  border-radius: 20px;
  background-color: white;
  margin: 10px;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.news-element-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;
}

.news-element-header {
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  color: #512314;
  margin: 20px;
}

.news-element-summary {
  font-family: "Teachers", sans-serif;
  font-weight: 600;
  color: #512314;
  margin: 20px;
}

.news-element-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: white;
  border: 1px solid #d91604;
  border-radius: 20px;
  padding: 10px 20px;
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  color: #d91604;
  cursor: pointer;
}

@media (max-width: 800px) {
  .news-element {
    width: 50%;
  }
}

@media (max-width: 450px) {
  .news-element {
    width: 90%;
  }
}
