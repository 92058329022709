.data-graphs-page {
  background-color: #f5ebdc;
  padding-top: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.data-graphs-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-height: 83.9vh;
  margin-bottom: 10px;
}

.menu-graph {
  width: 90%;
}

.data-shown {
  display: flex;
  justify-content: center;
  color: #512314;
  font-family: "Teachers", sans-serif;
  font-optical-sizing: auto;
  padding-left: 0.5rem;
  font-weight: 550;
}

.empty-message {
  color: #512314;
  font-family: "Roboto Slab", serif;
  font-size: 48px;
}

@media (max-width: 450px) {
  .empty-message {
    width: 70%;
    text-align: center;
    font-size: 24px;
  }
}
