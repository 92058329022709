.doc-date-entry {
  width: 100%;
}

.doc-date-entry-label {
  margin-bottom: -20px;
  color: #d91604;
}

.doc-date-entry-label .day-label {
  color: #ff8731;
  padding-right: 8px;
}

.doc-date-entry-date {
  display: flex;
  font-size: 20px;
}

.doc-date-entry-date .label {
  font-family: "Roboto Slab", serif;
}

.doc-date-entry-date .date {
  font-family: "Teachers", sans-serif;
  font-weight: 450;
  margin-top: 2px;
  margin-left: 5px;
}

.doc-date-entry-activities {
  font-size: 20px;
}

.doc-date-entry-activities .label {
  font-family: "Roboto Slab", serif;
}

.doc-date-entry-activities ul {
  list-style-type: disc;
  padding-left: 25px;
  margin: 0;
}

.doc-date-entry-activities li {
  margin-bottom: 10px;
}

.doc-date-entry-activities .activity-label {
  font-weight: 500;
}

.doc-date-entry-activities .activity-description {
  font-family: "Teachers", sans-serif;
  font-weight: 450;
  margin-left: 5px;
}
