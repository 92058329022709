.map-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%; /* Aspect ratio for the map (1000 / 600 = 5/3 = 60%) */
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gradient-rect {
  stroke: #512314; /* Border color */
  stroke-width: 0.5px; /* Border width */
}

.scale-values {
  font-family: "Teachers", sans-serif;
  font-style: italic;
  font-weight: bold;
  fill: #512314;
}

.scale-label {
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  font-weight: bold;
  fill: #512314;
}

.state-info {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #f5ebdc;
  padding: 10px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */ /* Remove the box-shadow */
}

.state-info h2 {
  font-family: "Lilita One", sans-serif;
  color: #512314;
  margin: 0; /* Ensures no extra margin at the top */
}

.state-info p {
  margin: 5px 0 0 0; /* Adjust margins as necessary */
  color: #512314;
}

.state-info p span.item-name {
  font-family: "Roboto Slab", serif;
  font-size: 18px;
}

.state-info p span.item-value {
  font-family: "Teachers", sans-serif;
  font-size: 18px;
  margin-left: 5px;
}

.state-info p.no-state {
  font-family: "Lilita One", sans-serif;
  font-size: 24px;
  text-align: center;
}

.state-info p.no-data {
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  text-align: center;
}

.state-info p.item-note {
  font-family: "Teachers", sans-serif;
  font-size: 12px;
  font-size: 0.8em;
}

@media (max-width: 450px) {
  .map-container {
    margin-bottom: 55px;
  }

  .state-info {
    translate: -93px 90px;
    width: auto;
    height: auto;
  }

  .state-info h2 {
    font-size: 1.2em;
  }

  .state-info p {
    font-size: 0.9em;
  }

  .state-info p span.item-name,
  .state-info p span.item-value {
    font-size: 0.9em;
  }

  .state-info p.no-state {
    font-size: 1.2em;
  }

  .state-info p.no-data {
    font-size: 0.9em;
  }
}
