.bar-graph-container {
  width: 75%;
}

.bar-graph-container canvas {
  background-color: #f5ebdc;
}

.bar-graph-container .chartjs-render-monitor {
  color: #512314;
}

.bar-graph-container .chartjs-render-monitor .chartjs-tooltip {
  background-color: #ff8731;
}

.bar-graph-container .chartjs-render-monitor .chartjs-tooltip-body {
  background-color: #ff8731;
}

.selected-bar-info {
  color: #512314;
  text-align: center;
}

.selected-bar-info span {
  font-size: 20px; /* Increased font size */
}

.selected-bar-info span:first-child {
  font-family: "Roboto Slab", serif;
}

.selected-bar-info span:nth-child(2) {
  font-family: "Roboto Slab", serif;
}

.selected-bar-info span:last-child {
  font-family: "Teachers", sans-serif;
  margin-left: 10px; /* Added a small gap */
}

@media (max-width: 450px) {
  .bar-graph-container {
    width: 90%;
  }
}
