.contact-me-page {
  background-color: #f5ebdc;
  padding-top: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-me-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-height: 82.8vh;
  margin-bottom: 10px;
}

.contact-me-page-title h1 {
  color: #512314;
  font-family: "Roboto Slab", serif;
  font-size: 36px;
  border-bottom: 3px solid;
  padding-left: 10px;
  padding-right: 10px;
}

.contact-me-page-text {
  width: 100%;
  align-items: left;
  color: #512314;
  font-family: "Teachers", sans-serif;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}

.contact-me-page-text a {
  color: #512314;
  padding-left: 10px;
}

@media (max-width: 1200px) {
  .contact-me-page-text {
    width: 80%;
    font-size: 24px;
    word-break: normal;
  }
}

@media (max-width: 700px) {
  .contact-me-page-text {
    width: 80%;
    font-size: 18px;
  }
}
