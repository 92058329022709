footer {
  background-color: #512314;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-text {
  color: #f5ebdc;
  font-family: "Lilita One", sans-serif;
  font-size: 1rem;
}

@media (max-width: 450px) {
  footer {
    height: 4rem;
  }
  .footer-text {
    width: 90%;
    text-align: center;
  }
}
